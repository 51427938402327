@use "../../scss/" as *;

.footer {
    position: relative;
    padding: 6rem 2rem;
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;

    &__content {
        max-width: 1000px;
        margin: 0 auto; // Center the content

        &__logo {
            @include flex(center, center);
            margin-bottom: 3rem;
            text-align: center;

            .logo {
                display: flex;
                align-items: center;
                img {
                    height: 50px;
                    margin-right: 1rem;
                }
                a {
                    font-size: 2rem;
                    font-weight: bold;
                    color: #fff;
                }
            }
        }

        &__info {
            text-align: center;
            font-size: 1.2rem;
            color: #fff;
            line-height: 1.5;
            margin-top: 2rem;
            
            p {
                margin: 0.5rem 0;
            }
        }
    }
}
